<template>
  <div class="container">
    <div class="live-detail">
      <div class="header">
        <img class="main-banner" :src="info.cover" alt="">
        <p class="title">{{info.title}}</p>
      </div>
      <van-loading type="spinner" color="#1989fa" class="loading" v-if="showLoading"/>
      <van-tabs class="has-bg-tabs" v-model="activeName" @click="changeTab()">
        <van-tab title="内容介绍" name="1">
          <!-- 内容介绍 -->
          <div class="content-intro">
            <div class="advance-notice">
              <p class="advance-title">{{contentTime}}</p>
              <p class="advance-joiner">{{info.sign_num}}人报名</p>
              <div class="advance-op">
                <p class="btn btn-join" v-if="info.ing == 1" @click="toLiving(info.living, info.id)">进入直播</p>
                <!-- <p class="btn btn-share">分享</p>-->
                 <p class="btn btn-join" v-if="show && info.is_sign == 0" @click="toBVue('/join', info.id)">报名</p>
                 <p class="btn btn-join active" v-if="show && info.is_sign == 1">已报名</p>
              </div>
            </div>
            <div class="intro-msg">
              <p>讲师：{{info.speaker}}</p>
            </div>
            <div class="rich-text" v-html="info.content"></div>
          </div>
        </van-tab>
        <van-tab title="全部评价" name="2">
          <div class="appraise-full-box">
            <van-empty :description="word" v-if="commentList.length == 0" />
            <div class="content-appraise" v-if="commentList.length > 0">
              <van-list
                      v-model="loading"
                      :finished="finished"
                      finished-text="没有更多了"
                      @load="onRefresh"
              >
                <div class="appraise-item" v-for="(item, index) in commentList" :key="index">
                  <img class="appraise-user" :src="item.avatarurl" alt="">
                  <div class="appraise-detail">
                    <div class="appraise-detail-span">
                      <span>{{item.nickname}}</span>
                      <span>{{item.timeShow}}</span>
                    </div>
                    <p>{{item.content}}</p>
                  </div>
                </div>
              </van-list>
            </div>
            <div class="comment-input">
              <input placeholder="请输入评价内容" maxlength="200" @input="changeValue('content')"
                     v-model.trim="formData.content"
              />
              <p class="btn" @click="createComment()">发送</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
  import jWeixin from 'weixin-jsapi';
  import { Dialog } from 'vant';
export default {
  name: 'LiveDetail',
  data(){
    return {
      activeName: '1',
      contentTime: '',
      show: false,
      showLoading: false,
      info: {
        sign_num: 0
      },
      commentList: [],
      formData: {
        'id': 0,
        'action': 'live_trailer',
        'content': '',
        'offset': 0,
        'limit': 10,
      },
      word: '数据加载中...',
      loading: false,
      refreshStatus: false,
      finished: false,
      userinfo: {},
      jsapi: {},
      signature: {}
    }
  },
  created() {
    const id = this.$untils.getUrlKey('id');
    this.formData.id = id;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.getInfo(id);
  },
  mounted() {
  },
  methods:{
    changeTab() {
      if (this.activeName == 2) {
        this.refreshStatus = false
        this.formData.offset = 0
        this.getComment()
      }
    },
    onRefresh() {
      this.getComment()
    },
    // 获取直播票据
    toLiving(living, id) {
      let that = this
      if (that.showLoading == true) {
        return false;
      }
      let formData = {
        'id':id
      }
      that.$api.LIVING_POST(formData).then(res => {
        if (res.code) {
          that.showLoading = false
          Dialog({ message: '初始化直播间失败' })
          return false;
        } else {
          that.signature = res.signture;
          console.log(that.signature)
          that.configWx();
        }
      })
    },
    configWx() {
      let that = this
      that.showLoading = true
      jWeixin.config({
        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: that.signature.corpid, // 必填，企业微信的corpID
        timestamp: that.signature.timestamp, // 必填，生成签名的时间戳
        nonceStr: that.signature.noncestr, // 必填，生成签名的随机串
        signature: that.signature.configtick,// 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ['startLiving'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      })
      jWeixin.ready(function(){
        that.agentConfigWx()
      })
      jWeixin.error(function(){
        that.showLoading = false
        Dialog({message: "该版本不支持此功能"})
      });
    },
    agentConfigWx() {
      let that = this
      jWeixin.agentConfig({
        corpid: that.signature.corpid,
        agentid: that.signature.agentid,
        timestamp: that.signature.timestamp, // 必填，生成签名的时间戳
        nonceStr: that.signature.noncestr,
        signature: that.signature.jsapitick,
        jsApiList: ['startLiving'], //必填，传入需要使用的接口名称
        success: function() {
          that.startLiving()
        },
        fail: function(res) {
          if(res.errMsg.indexOf('function not exist') > -1){
            that.showLoading = false
            Dialog({ message: '版本过低请升级' });
          }
        }
      })
    },
    startLiving() {
      let that = this
      jWeixin.invoke('startLiving', {
        "livingId": that.info.livingid,
      }, function(res) {
        that.showLoading = false
        if (res.err_msg == "startLiving:ok") {
          console.log(res.livingId)
        }
      });
    },
    //获取评论
    getComment() {
      let that = this
      if (that.refreshStatus) {
        return false
      }
      if (that.formData.offset == 0) {
        that.commentList = []
      }
      that.refreshStatus = true
      this.$api.LIST_COMMENT_POST(that.formData).then(res => {
        if (that.formData.offset > 0 && res.length == 0) {
          that.refreshStatus = false
          that.finished = true
        }
        if (res.length === 0) {
          that.refreshStatus = false
          that.word = '暂无数据'
          return false
        }
        res.forEach((item) => {
          that.commentList.push({
            id: item.id,
            nickname: item.nickname,
            content: item.content,
            avatarurl: item.avatarurl ? item.avatarurl : '',
            timeShow: item.create_time
          })
        })
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        } else {
          that.finished = true
        }
        that.loading =false
        that.refreshStatus = false
      })
    },
    //提交评论
    createComment() {
      let that = this
      if (that.showLoading == true) {
        return false
      }
      if (!that.formData.content) {
        Dialog({message: "请输入评论内容"})
        return
      }
      that.showLoading = true
      this.$api.CREATE_COMMENT_POST(that.formData).then(res => {
        if (!res.code) {
          that.commentList.unshift({'id': res.id, 'content': that.formData.content, 'nickname': that.userinfo.username, 'avatarurl': that.userinfo.avatarUrl, 'timeShow': res.create_time})
        }
        Dialog({ message: res.msg });
        that.formData.content = ''
        that.showLoading = false
      })
    },
    //文本验证
    changeValue() {
      let that = this
      let value = that.formData.content; //校验的字段
      // 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/;
      let maxLimitStr = 200; //25个汉字
      let pass = true;
      let substringStr = "";
      let length = 0;
      let strArr = value.split("");
      strArr.map((str) => {
        if (pass) {
          if (cnReg.test(str)) {//中文
            length++;
          } else {//英文
            length += 0.5;
          }
          //大于最大限制的时候
          if (length > maxLimitStr) {
            pass = false;
            Dialog({message: "文字长度已超出最大值，最大值为" + maxLimitStr * 2 + "个字符"})
            //将校验的字符截取返回
            that.formData.content = substringStr;
          } else {
            substringStr += str;
          }
        }
      });
      return pass;
    },
    //倒计时
    countdowm(timestamp){
      let that = this;
      let timer = setInterval(function(){
        let nowTime = new Date();
        let endTime = new Date(timestamp * 1000);
        let t = endTime.getTime() - nowTime.getTime();
        if (t > 0) {
          let day = Math.floor(t / 86400000);
          let hour=Math.floor((t / 3600000)%24);
          let min=Math.floor((t / 60000)%60);
          let sec=Math.floor((t / 1000)%60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          let format = '';
          if(day > 0){
            format = `${day}天${hour}小时${min}分${sec}秒`;
          }
          if(day <= 0 && hour > 0 ){
            format = `${hour}小时${min}分${sec}秒`;
          }
          if(day <= 0 && hour <= 0){
            format =`${min}分${sec}秒`;
          }
          that.contentTime = format;
          that.show = true
        } else {
          clearInterval(timer);
          that.contentTime = '报名已结束'
          that.show = false
        }
      },1000);
    },
    //获取详情
    getInfo(id) {
      let that = this
      this.$api.LIVETRAILER_DETAIL_GET({
        'id': id
      }).then(res => {
          that.info = res
          that.countdowm(res.start_time)
      })
    },
    toBVue(toPath, toid) {
      this.$router.push({path: toPath, query: {id: toid}})
    }
  }
}
</script>

<style lang='less' scoped>
  .live-detail .main-banner{
    display:block;
    width:100%;
    margin-top:10px;
  }

  .live-detail .title{
    display:block;
    font-size:19px;
    color:#000;
    padding-top:16px;
    padding-bottom: 24px;
  }

  .loading {
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 10px;
    position:fixed;
    z-index: 1000;
    left:50%;
    top:50%;
    margin-top:-30px;
    margin-left:-30px;
  }
</style>
